import {UserscomplianceRepository} from '../../core/repositories/UsersComplianceRepository';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {PeriodsService} from '../appServices/PeriodsService';
import {
	PeriodKind,
	ComplianceKind,
	CallContext,
	UsersComplianceResultsRequest,
	ServiceRequest,
	ServiceResponse,
	UserComplianceResultDTO,
} from '../../core/ModelContracts';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {map} from 'rxjs/operators';
import {UserComplianceResult} from '../../core/Models';
import {AuthenticationService} from '../appServices/AuthenticationService';
import {ServiceFactory} from '../factories/ServiceFactory';

export class UsersComplianceApiRepository implements UserscomplianceRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private periodService: PeriodsService,
		private authenticationService: AuthenticationService
	) {}

	getAllBy = (periodType: PeriodKind, complianceType?: ComplianceKind) =>
		this.requestUsersCompliance(periodType, complianceType).pipe(
			map((response) => response.Response),
			map((ucrDTOs) => ucrDTOs.map((ucrDTO) => UserComplianceResult.createFrom(ucrDTO)))
		);

	getAllByWithPeriod = (periodPolicy: string, periodType: PeriodKind, complianceType?: ComplianceKind) =>
		this.requestUserComplianceWithPeriod(periodPolicy, periodType, complianceType).pipe(
			map((response) => response.Response),
			map((ucrDTOs) => ucrDTOs.map((ucrDTO) => UserComplianceResult.createFrom(ucrDTO)))
		);

	requestUsersCompliance = (periodType: PeriodKind, complianceType?: ComplianceKind) => {
		const currentPeriodFormated = this.periodService.penaltiesAndRewardsCurrentPeriod.format('YYYY-MM-DD');

		return this.requestUserComplianceWithPeriod(currentPeriodFormated, periodType, complianceType);
	};

	requestUserComplianceWithPeriod(periodPolicy: string, periodType: PeriodKind, complianceType?: ComplianceKind) {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const userscomplianceresultsUrl = urlBuilder.getBackendUrl(BackendRoutes.userscomplianceresults);
		console.log(userscomplianceresultsUrl);
		const callContext = this.authenticationService.callContext;

		const request = UsersComplianceResultsRequest.create(
			this.policyService.currentPolicyId,
			periodPolicy,
			periodType,
			complianceType
		);

		const serviceRequest = ServiceRequest.create<UsersComplianceResultsRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<UserComplianceResultDTO[]>>(
			userscomplianceresultsUrl,
			'POST',
			serviceRequest
		);
	}
}
